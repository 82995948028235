export default {
  //初始页面轮播图
  banners: [{
    linkUrl: '',
    picUrl: require("../assets/image/home/home_banner1.png")
  },
  {
    linkUrl: '',
    picUrl: require("../assets/image/home/home_banner2.png")
  },
    // {
    //   linkUrl: '',
    //   picUrl: 'https://dcdn.it120.cc/2019/12/29/daca65ee-4347-4792-a490-ccbac4b3c1d7.png'
    // },
    // {
    //   linkUrl: '',
    //   picUrl: 'https://dcdn.it120.cc/2019/12/29/8396f65d-d615-46d8-b2e5-aa41820b9fe5.png'
    // }
  ],
  // 初始界面分类
  categories: [{
    icon: require("../assets/image/home/1.png"),
    id: 1872,
    type: '1',
    name: "积分兑换",
  },
  {
    icon: require("../assets/image/home/taobao.png"),
    id: 1873,
    type: '2',
    name: "淘省钱",
    path: 'https://lt.591wsh.com/pages/tpCoupon/tbPage/index'
  },
  // {
  //   icon: require("../assets/image/home/2.png"),
  //   id: 1873,
  //   type: '2',
  //   name: "移动花卡"
  // },
  // {
  //   icon: "../assets/image/home/2.png",
  //   id: 1873,
  //   type:'2',
  //   name: "商品分类"
  // }, 
  {
    icon: require("../assets/image/home/3.png"),
    id: 1875,
    type: '3',
    name: "手机充值",
    path: 'https://lt.591wsh.com/pages/gooddetail/index?id=45327560609538048'
  },
  {
    icon: require("../assets/image/home/honbao.png"),
    id: 1875,
    type: '4',
    name: "红包天天领",
    path: 'https://lt.591wsh.com/hg/redEnvelopes'
  },

  {
    icon: require("../assets/image/home/xiecheng.png"),
    id: 1875,
    type: '7',
    name: "携程出行",
    path: 'https://m.ctrip.com/html5/?AllianceID=1247374&sid=3020477&ouid=&app=0101F00'
  },
  {
    icon: require("../assets/image/home/duoduo.png"),
    id: 1875,
    type: '6',
    name: "多多省钱",
    path: 'https://lt.591wsh.com/pages/tpCoupon/pddPage/index'
  },
  {
    icon: require("../assets/image/home/jiudian.png"),
    id: 1875,
    type: '8',
    name: "酒店预定",
    path: 'https://i.meituan.com/awp/hfe/block/889b556271262669e7fa/118455/index.html?urpid=71819.165242632416.97858026.7&appkey=f6fbb3094bcd29f73a5f06680ee9ee92%3A001&source=wandie&_rdt=1&noguide=1&actid=7'
  },
  // {
  //   icon: require("../assets/image/home/guanyin.png"),
  //   id: 1875,
  //   type: '7',
  //   name: "特惠观影"
  // },
  {
    icon: require("../assets/image/home/suixin.png"),
    id: 1875,
    type: '9',
    name: "随心选",
    path: 'https://young.gd10010.cn/116114/#/optional?commid=202109220000&channelid=51bb9ql'
  },
    // {
    //   icon: require("../assets/image/home/4.png"),
    //   id: 1906,
    //   type: '4',
    //   name: "燃气费"
    // }, {
    //   icon: require("../assets/image/home/5.png"),
    //   id: 1907,
    //   type: '5',
    //   name: "水费"
    // }, {
    //   icon: require("../assets/image/home/6.png"),
    //   id: 2016,
    //   type: '6',
    //   name: "生活缴费"
    // }, {
    //   icon: require("../assets/image/home/8.png"),
    //   id: 1875,
    //   type: '7',
    //   name: "加油"
    // },
  ],
  //积分页面分类
  pointsList: [{
    id: '1',
    type: '1',
    name: '中国移动',
    icon: require("../assets/image/home/yidong.png"),
    path: 'https://m.jf.10086.cn/#/pages/index/index'
  },
  {
    id: '8',
    type: '8',
    name: '号卡中心',
    icon: require("../assets/image/home/haoka.png"),
    path: 'https://m.jf.10086.cn/#/pages/index/index'
  },
  {
    id: '2',
    type: '2',
    name: '中国联通',
    icon: require("../assets/image/home/liantong.png"),
    path: 'https://m.10010.com/scaffold-show/home/36'
  },
  {
    id: '3',
    type: '3',
    name: '中国电信',
    icon: require("../assets/image/home/dianxin.png"),
    path: 'https://m.telefen.com/MobileSSOV2/Login.aspx?channelCode=SWX&goBack=https%3a%2f%2fmomall.telefen.com%2fProvinceWechat%2fPersonalCenter%2fLogin'
  },
  {
    id: '4',
    type: '4',
    name: '中国银行',
    icon: require("../assets/image/home/zhongguo.png"),
  },
  {
    id: '5',
    type: '5',
    name: '东方航空',
    icon: require("../assets/image/home/donfang.png"),
  },
  {
    id: '6',
    type: '6',
    name: '国家电网',
    icon: require("../assets/image/home/dianwang.png"),
  },
  {
    id: '7',
    type: '7',
    name: '广发银行',
    icon: require("../assets/image/home/guangfa.png"),
  },
  {
    id: '9',
    type: '9',
    name: '品胜',
    icon: require("../assets/image/home/Ecard.png"),
    path: 'https://m.jf.10086.cn/#/pages/index/index'
    },
    {
      id: '10',
      type: '10',
      name: '咪咕',
      icon: require("../assets/image/home/mogu.jpg"),
      path: 'https://m.jf.10086.cn/#/pages/index/index'
    }
  ],
  //我的团队
  teamList: [{
    id: '1',
    type: '1',
    name: '我的团队',
    icon: require("../assets/image/person/team1.png"),
  },
  {
    id: '2',
    type: '2',
    name: '我的佣金',
    icon: require("../assets/image/person/team2.png"),
  },
  {
    id: '3',
    type: '3',
    name: '订单详情',
    icon: require("../assets/image/person/team3.png"),
  },
  {
    id: '4',
    type: '4',
    name: '订单统计',
    icon: require("../assets/image/person/team4.png"),
  }
  ],
  //我的订单
  orderList: [{
    id: '1',
    type: '1',
    name: '待付款',
    icon: require("../assets/image/person/order1.png"),
  },
  {
    id: '2',
    type: '2',
    name: '待发货',
    icon: require("../assets/image/person/order2.png"),
  },
  {
    id: '3',
    type: '3',
    name: '待收货',
    icon: require("../assets/image/person/order3.png"),
  },
  {
    id: '4',
    type: '4',
    name: '已完成',
    icon: require("../assets/image/person/order4.png"),
  },
  {
    id: '5',
    type: '5',
    name: '售后服务',
    icon: require("../assets/image/person/order5.png"),
  }
  ],
  // 分类
  firstCategories: [{
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/f89753a227d26a3fe9ccc6f975857bb6.png",
    id: 1,
    name: "上装",
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/5bfffd6ad0d4483870f024a3ed936528.png",
    id: 2,
    name: "裤装"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/8d32c254e2cb86d2d42c99b768d136b6.png",
    id: 3,
    name: "特价区"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/d800327091f216e2c83db8af7b6be306.png",
    id: 4,
    name: "裙装"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/cfee29650d6ae58a4bb1f84a3d899450.png",
    id: 5,
    name: "套装"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/6b3136cda73c99453ac93a1c5a9deebf.png",
    id: 6,
    name: "外套"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/f89753a227d26a3fe9ccc6f975857bb6.png",
    id: 7,
    name: "上装",
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/5bfffd6ad0d4483870f024a3ed936528.png",
    id: 8,
    name: "裤装"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/8d32c254e2cb86d2d42c99b768d136b6.png",
    id: 9,
    name: "特价区"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/d800327091f216e2c83db8af7b6be306.png",
    id: 10,
    name: "裙装"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/cfee29650d6ae58a4bb1f84a3d899450.png",
    id: 11,
    name: "套装"
  }, {
    icon: "https://cdn.it120.cc/apifactory/2019/04/09/6b3136cda73c99453ac93a1c5a9deebf.png",
    id: 12,
    name: "外套"
  }],
  // 商品

  //人气推荐部分
  recommand: [{
    id: "50",
    name: "人气推荐",
    img: "https://bucket-1300705541.cos.ap-chengdu.myqcloud.com/mishop/home/home_4.jpg",
    title: "清洁湿巾",
    detail: "20片/包*5包/母婴适用/RO纯水/柔软细腻/温和无刺激",
    price: "￥9.9",
    section: [{
      id: "51",
      img: "https://bucket-1300705541.cos.ap-chengdu.myqcloud.com/mishop/home/home_5.jpg",
      title: "最生活毛巾",
      text: "阿瓦提长绒棉/瑞典抗菌科...",
      price: "￥19.9"
    },
    {
      id: "52",
      img: "https://bucket-1300705541.cos.ap-chengdu.myqcloud.com/mishop/home/home_6.jpg",
      title: "贝医生牙刷",
      text: "瑞典进口毛刷/专利科学布...",
      price: "￥8.9"
    },
    {
      id: "53",
      img: "https://bucket-1300705541.cos.ap-chengdu.myqcloud.com/mishop/home/home_7.jpg",
      title: "Type-C耳机",
      text: "USB Type-C接口/动圈+陶...",
      price: "￥99"
    },
    {
      id: 54,
      img: "https://bucket-1300705541.cos.ap-chengdu.myqcloud.com/mishop/home/home_8.jpg",
      title: "小米手环3/4通用...",
      text: "顺滑柔软、触感舒适...",
      price: "￥19.9"
    }
    ]
  }],
  //银行卡图片
  bankMap: {
    '招商银行': require('../assets/image/banck/CMB.png'),
    '工商银行': require('../assets/image/banck/ICBC.png'),
    '建设银行': require('../assets/image/banck/CCB.png'),
    '浦发银行': require('../assets/image/banck/SPDB.png'),
    '农业银行': require('../assets/image/banck/ABC.png'),
    '民生银行': require('../assets/image/banck/CMBC.png'),
    '兴业银行': require('../assets/image/banck/CIB.png'),
    '平安银行': require('../assets/image/banck/SPABANK.png'),
    '交通银行': require('../assets/image/banck/COMM.png'),
    '中信银行': require('../assets/image/banck/CITIC.png'),
    '光大银行': require('../assets/image/banck/CEB.png'),
    '上海银行': require('../assets/image/banck/SHBANK.png'),
    '华夏银行': require('../assets/image/banck/NBBANK.png'),
    '中国银行': require('../assets/image/banck/BOC.png'),
    '广发银行': require('../assets/image/banck/GDB.png'),
    '南京银行': require('../assets/image/banck/NJCB.png'),
    '宁波银行': require('../assets/image/banck/NBBANK.png'),
    '邮政储蓄银行': require('../assets/image/banck/PSBC.png'),
  }
}